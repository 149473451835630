import { useState, useEffect } from "react";

export default function useResize(ref, compactUntil = 800) {
    const [width, setWidth] = useState(null);
    const [className, setClassName] = useState("");

    useEffect(() => {
        setClassName(width <= compactUntil ? "compact" : "")
    }, [width, compactUntil])

    useEffect(() => {
        if (ref?.current?.clientWidth) {
            setWidth(ref.current.clientWidth)
        }
    }, [ref])

    useEffect(() => {
        const setCurrentWidth = () => {
            if (ref?.current?.clientWidth) {
                setWidth(ref.current.clientWidth)
            }
        }
        window.addEventListener('resize', setCurrentWidth)

        setTimeout(setCurrentWidth, 5);
        const int$ = setInterval(setCurrentWidth, 750);

        return () => {
            window.removeEventListener('resize', setCurrentWidth);
            clearInterval(int$);
        }
    }, [])

    return className
}