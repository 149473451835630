import { useState, useEffect, cloneElement, useMemo } from "react"
import lang from "./lang";
import repo from "./repo";

export default function useUpdate(uploads, allUploadsDone) {

    const [data, setData] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [existing, setExisting] = useState(null);

    /** If the finished upload is saved, fetch the tags. */
    useEffect(() => {
        if (uploads.length && uploads.every(it => it.saved)) {
            setLoading(true);
            repo.fetchTags(uploads)
            .then(tags => {
                setExisting(tags)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [uploads])

    useEffect(() => {
        if (allUploadsDone && uploads && !updating && !loading && data) {
            // console.table({ allUploadsDone, uploads, data });
            update();
        }
    }, [uploads, allUploadsDone, data, loading])


    /**
     * This method will fire the update call to s3.
     */
    async function update() {
        if (!updating) {
            setUpdating(true);
            setStatus(lang.get('uploader_file_details_updating'));
            try {
                await repo.update(uploads, data);
                setStatus(lang.get('uploader_file_details_saved'));
            } catch (err) {
                console.error(err)
                setStatus(lang.get('uploader_file_details_update_failed'));
            }
            setUpdating(false);
        }
    }

    /**
     * This method will set the internal queued data.
     * @param {{ alt: String, title: String }} newData The new data set in the File Details panel.
     */
    function changeData(newData) {
        setData(newData);
        setStatus(lang.get('uploader_file_details_update_waiting'));
    }

    function clearExisting() {
        setExisting(null);
        setStatus(null);
    }

    return {
        updating,
        loading,
        status,
        existing,
        clearExisting,
        changeData
    }
}