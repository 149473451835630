import React, { useEffect, useMemo, useRef } from 'react';
import constants from "../utils/constants";
import getReadableFileSizeString from '../utils/getReadableFileSizeString';
import lang from '../utils/lang';
import "../styles/cards.scss";

const NewUpload = ({ upload: { file, status, message }, onRemove = () => { } }) => {
    const img_src = useMemo(() => /image/.test(file?.type) ? URL.createObjectURL(file) : constants.DEFAULT_PREVIEW_IMAGE, [file]);
    const file_size = useMemo(() => getReadableFileSizeString(file.size), [file.size]);
    const cardRef = useRef(null);

    let statusString = status.toString().toLowerCase();
    if (statusString === "ready") {
        statusString = null;
    }

    const translatedStatusString = useMemo(() => lang.get(`uploader_status_${status.toString().toLowerCase()}`), [status]);
    const handleRemove = () => onRemove(file);

    useEffect(()=> {
        cardRef.current.focus();
    }, []);

    return (
        <div className={`has-${statusString} upload-card-container`}>
            <div className={`upload-card card border d-flex ${statusString}`} tabIndex="0" ref={cardRef}>
                <div className="img-container mr-3">
                    <img className="img-responsive my-auto" src={img_src} alt="" role="presentation" />
                </div>
                <h3 className="upload-card-name text-nowrap">{file.name}</h3>
                <div className="upload-card-status ml-auto d-flex align-items-center justify-content-center">
                    <span className="status text-nowrap" role="status">
                        {statusString === "uploading" || statusString === "scanning" ? <img className="pr-2" src="/static_path/images/loading_spinner.gif/" alt="" /> : null}
                        {statusString === "error" || statusString === "corrupt" ? <i className="fas fa-exclamation-circle"></i> : null}
                        <b className="pr-2"> {translatedStatusString}</b>
                    </span>
                    <span className="text-nowrap my-auto">{file_size}</span>
                </div>
                <button type="button" className="close" data-dismiss="alert" title="Remove File" onClick={handleRemove}>&times;</button>
            </div>
            <span className="help-block">{message}</span>
        </div>
    )
};

export default NewUpload;