function tryImage(source) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = resolve
        img.onerror = reject
        img.onabort = reject
        img.src = source

    })
}

export default async function checkMimeType(file) {
    try {
        await tryImage(file)
        return "image"
    } catch (error) {
        // Failed to load the image. Returning based on file extension.
        return "file"
    }
}