const UploadStatus = {
    READY: "READY",
    UPLOADING: "UPLOADING",
    SCANNING: "SCANNING",
    ERROR: "ERROR",
    CORRUPT: "CORRUPT",
    COMPLETE: "COMPLETE",
}

const DEFAULT_PREVIEW_IMAGE = "/static_path/images/course_splash/course-default-thumbnail.png/";

export default {
    UploadStatus,
    DEFAULT_PREVIEW_IMAGE
}