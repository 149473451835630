import { useState, useMemo, useEffect } from "react"
import constants from "./constants";
import emitter from "./emitter";
import repo from "./repo";

let fileCount = 0;

export default function useUploads(existingUploads, name, pollingSeconds = 2) {
    const [existing, setExisting] = useState([ ...(Array.isArray(existingUploads) ? existingUploads : [existingUploads].filter(Boolean)) ])
    const [newUploads, setNewUploads] = useState([]);

    /** A collection of existing and new uploads. */
    const uploads = useMemo(() => [...existing, ...newUploads], [existing, newUploads])
    /** A flag that determines if any uploads are currently being scanned/uploaded. */
    const uploading = useMemo(() => {
        return newUploads.some(it => it.status === constants.UploadStatus.SCANNING || it.status === constants.UploadStatus.UPLOADING)
    }, [newUploads])
    /** A collection of uploads that are sorted for presentation. */
    const sortedUploads = useMemo(() => {
        const arr = [...newUploads]
        arr.sort((a,b) => a.sort-b.sort);
        return arr;
    }, [newUploads]);
    /** A collection of uploads that are ready to upload. */
    const preparedUploads = useMemo(() => newUploads.filter(it => it.status === constants.UploadStatus.READY), [newUploads]);
    /** A collection of uploads that are scanned. */
    const cleanedUploads = useMemo(() => newUploads.filter(it => it.status === constants.UploadStatus.COMPLETE), [newUploads]);
    /** A flag that determines if all the current uploads are done. */
    const allUploadsDone = useMemo(() => uploads.length ? uploads.every(it => {
        switch(it.status) {
            case constants.UploadStatus.READY:
            case constants.UploadStatus.UPLOADING:
            case constants.UploadStatus.SCANNING:
                return false;
            default:
                return true;
        }
    }) : null, [uploads])
    /** A collection of associated upload data for the SLEd service. */
    const cleanedUploadData = useMemo(() => cleanedUploads.map(({ key, file, started, ended }) => ({
        key,
        name: file.name,
        type: file.type,
        input: name,
        size: file.size,
        ended,
        started,
        elapsed: (ended - started)
    })), [cleanedUploads])
    /** A string containing all the upload keys joined by a comma. This value is expected to be placed in the input to send back to the form post. */
    const cleanedUploadsString = useMemo(() => {
        const arr = existing?.length ? existing : newUploads
        if (!existing?.length && !allUploadsDone) {
            return ''
        }
        return arr.map(it => it.key).join(',')
    }, [allUploadsDone, existing]);

    const clearExisting = () => {
        emitter.removed(name, existing);
        setExisting(null);
    }
    const removeExisting = url => {
        emitter.removed(name, url);
        setExisting(existing.filter(it => it.url !== url))
    }

    useEffect(() => {
        if (cleanedUploadData && allUploadsDone) {
            emitter.uploaded(name, cleanedUploadData[0]);
        }
    }, [cleanedUploadData, allUploadsDone]);

    const addFiles = (...newFiles) => setNewUploads([...newUploads, ...newFiles.map(file => ({ file, saved: false, sort: fileCount++, status: constants.UploadStatus.READY, key: null, polling: { jwt: null, session: null, interval: null } }))])
    const removeFile = file => {
        const upload = newUploads.filter(upload => upload.file === file)?.[0]
        clearInterval(upload.polling.interval);
        emitter.removed(name, upload.key);
        setNewUploads(newUploads.filter(upload => upload.file !== file));
    };
    const changeFile = (file, { status, message, jwt, session, key, ended }) => {
        const upload = newUploads.filter(it => it.file === file)?.[0]
        if (upload) {
            upload.status = status ?? upload.status;
            upload.key = key ?? upload.key;
            upload.polling.jwt = jwt ?? upload.polling.jwt;
            upload.polling.session = session ?? upload.polling.session;
            upload.started = upload.started ?? new Date();
            upload.ended = ended ?? null;
            upload.message = message ?? null;

            if (jwt || session) {
                clearInterval(upload.polling.interval);
                upload.polling.interval = setInterval(async () => {
                    const newStatus = await repo.poll(upload.polling.jwt, upload.polling.session);
                    let ended = null;
                    switch(newStatus) {
                        case constants.UploadStatus.COMPLETE:
                        case constants.UploadStatus.CORRUPT:
                        case constants.UploadStatus.ERROR:
                            ended = new Date();
                            clearInterval(upload.polling.interval)
                            break;
                        }
                    changeFile(upload.file, { status: newStatus, message: message, ended  });
                }, pollingSeconds * 1000);
            }
            setNewUploads([...newUploads.filter(it => it.file !== file), upload]);
        }
    }

    return {
        uploads,
        preparedUploads,
        sortedUploads,
        uploading,
        cleanedUploadsString,
        addFiles,
        removeFile,
        changeFile,
        existing,
        clearExisting,
        allUploadsDone,
        removeExisting
    }
}