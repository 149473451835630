const lang = new Map()

lang.set('uploader_status_ready', 'Ready')
lang.set('uploader_status_uploading', 'Uploading')
lang.set('uploader_status_scanning', 'Scanning')
lang.set('uploader_status_corrupt', 'Corrupt')
lang.set('uploader_status_error', 'Error')
lang.set('uploader_status_complete', 'Complete')
lang.set('uploader_add_file', 'Add File to Upload')
lang.set('uploader_add_files', 'Add Files to Upload')
lang.set('uploader_add_file_instructions', 'click or drag and drop to add a file')
lang.set('uploader_invalid_uploading', 'File uploading, please wait.')
lang.set('uploader_invalid_missing_file', 'Upload a file to continue.')
lang.set('uploader_add_files_instructions', 'click or drag and drop to add a files')
lang.set('uploader_max_file_size', 'Maximum file upload size is')
lang.set('uploader_file_details_header', 'File Details')
lang.set('uploader_file_details_alt_label', 'Alt Text')
lang.set('uploader_file_details_alt_helper', 'Describes the file for users with assistive technology')
lang.set('uploader_file_details_title_label', 'Title Text')
lang.set('uploader_file_details_title_helper', 'Provides additional information about the file')
lang.set('uploader_file_details_update', 'Update')
lang.set('uploader_file_details_invalid_char', 'Enter alphanumeric or the following characters: +-=._:/@')
lang.set('uploader_file_details_loading', 'Loading...')
lang.set('uploader_file_details_updating', 'Updating file attributes..')
lang.set('uploader_file_details_update_failed', 'Update failed! Please check your data.')
lang.set('uploader_file_details_update_waiting', 'Waiting for file upload to complete...')
lang.set('uploader_file_details_saved', 'Saved ✓')
lang.set('uploader_generic_error', 'Something has gone wrong. Please try again.')

export default lang;