import React, { useEffect, useMemo } from "react";
import lang from "../utils/lang";
import { useForm } from "react-hook-form";
import cn from "../utils/cn";

const FileDetails = ({ uploads, update }) => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const validate = {
        charLength: (value) => (value) ? value.length < 256 : true,
        invalidChars: (value) => (value) ? /^[\w\ \+\-\=\.\_\:\/@]+$/.test(value) : true,
    };

    const isImageFile = useMemo(() => uploads?.every(it => {
        const type = it.type || it.file.type;
        return /image/gmi.test(type);
    }));

    useEffect(() => {
        if (update.existing) {
            setValue('alt', update.existing.alt);
            setValue('title', update.existing.title);
        }
    }, [update.existing]);

    return (
        <form onSubmit={handleSubmit(update.changeData)} className={cn("card file-details shadow", update.loading && "loading")}>
            <div className="file-details-header mb-3">
                {lang.get('uploader_file_details_header')}
            </div>
            {
                isImageFile && (
                    <div className={cn('mb-3', errors?.alt ? "has-error" : "")}>
                        <label className="control-label" htmlFor="altText">{lang.get('uploader_file_details_alt_label')}</label>
                        <input maxLength="256" {...register("alt", { validate })} className="form-control" />
                        <div className="text-danger">
                            {errors?.alt && errors?.alt?.type === "invalidChars" && (<span>{lang.get('uploader_file_details_invalid_char')}</span>)}
                        </div>
                        <small>{lang.get('uploader_file_details_alt_helper')}</small>
                    </div>
                )
            }
            <div className={cn('mb-3', errors?.title ? "has-error" : "")}>
                <label className="control-label" htmlFor="titleText">{lang.get('uploader_file_details_title_label')}</label>
                <input maxLength="256" {...register("title", { validate })} className="form-control" />
                <div className="text-danger">
                    { errors?.title && errors?.title?.type === "invalidChars" && (<span>{lang.get('uploader_file_details_invalid_char')}</span>) }
                </div>
                <small>{lang.get('uploader_file_details_title_helper')}</small>
            </div>
            <div className="file-details-action-row">
                <div className="file-details-notice">{ update.status }</div>
                <button className="btn btn-primary">{lang.get('uploader_file_details_update')}</button>
            </div>
            <div className="loader">{lang.get('uploader_file_details_loading')}</div>
        </form>
    )
};

export default FileDetails;