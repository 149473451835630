const emit = (name, event, data) => {
    // console.log('Emitting event:', `corpu:${name}:${event}`, data);
    const inputEvt = new CustomEvent(`corpu:${name}:${event}`, { detail: data });
    const globalEvt = new CustomEvent(`corpu:*:${event}`, { detail: data });

    window.dispatchEvent(inputEvt);
    window.dispatchEvent(globalEvt);
}

const uploaded = (name, upload) => emit(name, 'uploaded', upload);
const removed = (name, upload) => emit(name, 'removed', upload);
const status = (name, status) => emit(name, 'status', { status });

export default {
    uploaded,
    removed,
    status,
}