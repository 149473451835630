import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import $ from 'jquery';
import checkMimeType from './utils/checkMimeType';
import repo from './utils/repo';
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import lang from './utils/lang';
import constants from './utils/constants';

const fetchDataAttr = (el, value, defaultValue=null) => new Promise(resolve => {
    let count = 0;
    const int$ = setInterval(() => {
        const $script = $(el).find(`script[data-${value}]`);
        if (!$script.length) {
            resolve(defaultValue);
        }

        const dataValue = $script.data(value);
        if (dataValue && !/^{{/.test(dataValue)) {
            clearInterval(int$);
            resolve(dataValue);
        }
        
        if (count++ > 100) {
            clearInterval(int$);
            // console.error("Failed to fetch data value. It is still an angular template");
            resolve(defaultValue);
        }
    }, 25);
});

const getValueFile = async (value) => value ? typeof(value) === "string" ? { url: value, size: null, type: await checkMimeType(value) } : { ...value } : null

window.corpu = window.corpu ?? {}
window.corpu.registerUploader = async (context = {}, element, initData = {}) => {

  const el = typeof(element) === "string" ? $(element).get(0) : element
  if (!el) { throw Error("Udemy Business Leadership Academy Uploader: Failed to initialize the element."); }

  if (isEmpty(initData)) {
    initData = await repo.fetchInitData();
  }

  const [miscValue, miscPrefix, source, details=true] = await Promise.all([
    fetchDataAttr(el, 'value'),
    fetchDataAttr(el, 'prefix'),
    fetchDataAttr(el, 'source'),
    fetchDataAttr(el, 'details')
  ])
  
  context.prefix = miscPrefix || context.prefix;
  context.value = await getValueFile(miscValue ?? context.value);
  if (context.value) {
    context.value = { ...context.value, source, sort: -1, saved: true, status: constants.UploadStatus.COMPLETE }
  }
  context.initData = initData;
  context.details = details ?? context.details ?? true;

  each(initData.terms, (value, key) => lang.set(key, value))

  const root = ReactDOM.createRoot(el)
  root.render(
    <React.StrictMode>
      <App context={context} />
    </React.StrictMode>
  )
}