import React, { useMemo } from 'react';
import constants from "../utils/constants";
import getReadableFileSizeString from '../utils/getReadableFileSizeString';
import lang from '../utils/lang';
import "../styles/cards.scss";

const ExistingUpload = ({upload: { url, type, size }, onRemove = () => { } }) => {
    const img_src = useMemo(() => /image/.test(type) ? url : constants.DEFAULT_PREVIEW_IMAGE, [url, type]);
    const file_size = useMemo(() => getReadableFileSizeString(size), [size]);
    const [, file_name] = /([^\/]+\.\w+$)/.exec(url);
    const statusString = lang.get(`uploader_status_complete`);

    const handleRemove = () => onRemove(url);

    return (
        <div className={`has-${statusString} upload-card-container`}>
            <div className={`upload-card card border d-flex ${statusString}`} tabIndex="0">
                <div className="img-container mr-3">
                    <img className="img-responsive my-auto" src={img_src} alt="" role="presentation" />
                </div>
                <h3 className="upload-card-name text-nowrap">{file_name}</h3>
                <div className="upload-card-status ml-auto d-flex align-items-center justify-content-center">
                    <span className="status text-nowrap" role="status">
                        <b className="pr-2"> {statusString} </b>
                    </span>
                    <span className="text-nowrap my-auto">{file_size}</span>
                </div>
                <button type="button" className="close" data-dismiss="alert" title="Remove File" onClick={handleRemove}>&times;</button>
            </div>
        </div>
    )
};

export default ExistingUpload;