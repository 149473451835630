import React, { useRef, useEffect } from 'react';
import { FileDrop } from 'react-file-drop';
import NewUpload from './components/NewUpload';

import "./App.scss";
import repo from './utils/repo';
import constants from './utils/constants';
import useUploads from './utils/useUploads';
import ExistingUpload from './components/ExistingUpload';
import emitter from './utils/emitter';
import lang from './utils/lang';
import FileDetails from './components/FileDetails';
import useUpdate from './utils/useUpdate';
import useResize from './utils/useResize';
import cn from './utils/cn';

const App = ({ context }) => {
    const dropFilesSetState = (f, e) => {
        e.preventDefault();
        addFiles(...Array.from(f))
    };

    const { sortedUploads, uploads, preparedUploads, existing, removeExisting, addFiles, removeFile, allUploadsDone, changeFile, cleanedUploadsString, uploading } = useUploads(context?.value ?? null, context?.name ?? "uploader");
    const uploadRef = useRef(null);
    const uploaderContainerRef = useRef(null);
    const update = useUpdate(uploads, allUploadsDone);

    const resizedClass = useResize(uploaderContainerRef);

    useEffect(() => {
        if (uploadRef?.current && cleanedUploadsString) {
            uploadRef.current.setCustomValidity('');
        }
    }, [cleanedUploadsString]);

    const fileInputRef = useRef(null);
    const onFileInputChange = (event) => addFiles(...Array.from(event.target.files));

    const onTargetClick = () => {
        fileInputRef.current.click()
    };

    const handleRemoveFile = file => {
        update.clearExisting();
        removeFile(file);
    };

    const handleUpload = async () => {
        if (!preparedUploads.length) { return; }
        try {
            emitter.status(context?.name ?? "uploader", 'uploading');
            await Promise.all(preparedUploads.map(async upload => {
                changeFile(upload.file, { status: constants.UploadStatus.UPLOADING });
                try {
                    const { jwt, session, key } = await repo.upload(upload.file, context.prefix, context.fileName);
                    changeFile(upload.file, { status: constants.UploadStatus.SCANNING, jwt, session, key });
                }
                catch (err) {
                    if (err.response) {
                        switch (err.response.status) {
                            case 413:
                                changeFile(upload.file, { status: constants.UploadStatus.ERROR, message: `${lang.get('uploader_max_file_size')} 1GB.` });
                                return;
                            default:
                                changeFile(upload.file, { status: constants.UploadStatus.ERROR });
                        }
                    } else if (err.message) {
                        console.error('Uploader:', err.message);
                        changeFile(upload.file, { status: constants.UploadStatus.ERROR, message: lang.get('uploader_generic_error') });
                    } else {
                        changeFile(upload.file, { status: constants.UploadStatus.ERROR });
                    }
                }
            }));
        } catch (err) {
            emitter.status(context?.name ?? "uploader", "failed");
            console.log(err);
        }
    };

    useEffect(() => {
        if (allUploadsDone) {
            emitter.status(context?.name ?? "uploader", "finished");
        }
    }, [allUploadsDone]);

    useEffect(() => {
        handleUpload();
    }, [preparedUploads]);

    const handleRemove = url => {
        update.clearExisting();
        removeExisting(url);
    };

    return (
        <div ref={uploaderContainerRef} className={cn('uploader-react-comp', resizedClass)}>
            { existing.length > 0 && existing.map(upload => <ExistingUpload upload={upload} onRemove={handleRemove} key={upload.url} />) }
            { sortedUploads.map(upload => <NewUpload upload={upload} onRemove={handleRemoveFile} key={upload.file.name} />) }

            { existing.length && context.details ? <FileDetails uploads={uploads} update={update} /> : null }

            {(context.multi || sortedUploads.length < 1) && existing.length < 1 ?
                <div className="FileDrop">
                    <FileDrop
                        onDrop={(files, event) => dropFilesSetState(files, event)}
                        onTargetClick={onTargetClick}
                    >
                        <i className="fas fa-upload"></i>
                        {
                        context.multi ?
                            <span><p>{lang.get('uploader_add_files')}</p> <p><small>({lang.get('uploader_add_files_instructions')})</small></p></span>
                            : <span><p>{lang.get('uploader_add_file')}</p> <p><small>({lang.get('uploader_add_file_instructions')})</small></p> <p><small>{lang.get('uploader_max_file_size')} 1GB</small></p></span>
                        }
                        <input
                            onChange={onFileInputChange}
                            ref={fileInputRef}
                            type="file"
                            accept={context?.attrs?.accept ?? "*"}
                            className="sr-only"
                            multiple={context.multi}
                            aria-label={lang.get('uploader_add_files')}
                        />
                    </FileDrop>
                </div>
            : null}

            <input
                type="text"
                name={context.name}
                className="hidden"
                ref={uploadRef}
                readOnly
                onInvalid={e => e.target.setCustomValidity(uploading ? lang.get('uploader_invalid_uploading') : lang.get('uploader_invalid_missing_file'))}
                value={cleanedUploadsString}
                required={(context?.attrs?.required ?? false) || uploading}
                aria-label={lang.get('uploader_add_files')}
            />
        </div>
    );
};

export default App;
