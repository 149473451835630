import Axios from "axios"
import constants from "./constants";

const splitExtension = ({ name }) => {
    const [, ext = null] = /(\.[^.]+)$/gmi.exec(name);
    const file = name.replace(/(\.[^.]+)$/gmi, '')
    return [file, ext]
}

const api = {
    sled: Axios.create({
        baseURL: '/api'
    }),
    static: Axios.create({
        baseURL: '/api/static'
    })
}

async function fetchInitData() {
    try {
        const sled_resp = await api.sled.get('upload');
        if (sled_resp?.data) {
            return sled_resp?.data
        }
    } catch (err) {
        console.warn('Uploader: Failed to get initial data.')
        return {}
    }
}

async function upload(file, prefix, customName=null) {
    const [name, ext] = splitExtension(file);
    const { type } = file;

    const uploadFD = new FormData();
    uploadFD.set('ext', ext);
    uploadFD.set('name',  customName ?? name);
    uploadFD.set('type', type);
    uploadFD.set('prefix', prefix);
    uploadFD.set('file', file);

    const sled_resp = await api.sled.post('upload', uploadFD );
    if (sled_resp?.data) {
        const fd = new FormData();
        Object.entries(sled_resp?.data.signed.fields)
            .forEach(([key, value]) => fd.set(key, value))
        fd.set("file", file);
        const s3_resp = await fetch(sled_resp?.data.signed.url, {
            method: "POST",
            // mode: 'no-cors',
            body: fd
        });
        const { jwt, session, key } = sled_resp?.data;
        return { jwt, session, key }
    }
    return null;
}

async function poll(jwt, session) {
    try {
        const resp = await api.static.get('/avStatus', {
            headers: {
                'x-cu-security': jwt,
                'Authorization': session
            }
        });
        if (resp?.data?.["av-status"]) {
            switch(resp?.data?.["av-status"]) {
                case "CLEAN":
                    return constants.UploadStatus.COMPLETE
                case "INFECTED":
                    return constants.UploadStatus.CORRUPT
            }
        }
        return constants.UploadStatus.SCANNING;
    } catch (err) {
        switch(err.response.status) {
            case 400:
                // bad security token, likely invalid
                return constants.UploadStatus.ERROR;
            case 404:
                return constants.UploadStatus.SCANNING;
            default:
                return constants.UploadStatus.ERROR;
        }
    }
}

async function fetchTags(uploads) {
    const [{ url, source }] = uploads
    if (url || source) {
        try {
            const resp = await api.sled.get('/upload/attrs', { params: { url, source } })
            return resp?.data?.tags ? { title: resp.data.tags['ax-title'], alt: resp.data.tags['ax-alt'] } : null
        } catch (err) {
            // todo: catch S3 specific errors here.
        }
    }
    return null
}

async function update(uploads, data) {
    const [{ url, key, source }] = uploads
    if (url || key) {
        await api.sled.put('/upload/attrs', { url, key, source, data })
    }
}

export default {
    upload,
    update,
    poll,
    fetchInitData,
    fetchTags,
}